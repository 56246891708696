import React from "react";

export default ({ color = "white" }) => (
  <svg width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M91.7 61.5999L80.3 62.8999C80.3 63.0999 80.3 63.2999 80.3 63.3999C80 66.3999 78.4 68.4999 75.8 69.3999C70.8 70.9999 59.2 73.4999 58.7 73.5999C58.5 73.5999 58.4 73.5999 58.2 73.5999L45 73.2999C43.6 73.2999 42.6 72.1999 42.6 70.7999C42.6 69.4999 43.7 68.3999 45.1 68.4999L57.4 68.7999C57.8 68.7999 58.2 68.7999 58.5 68.6999C61.3 68.0999 69.8 66.1999 73.8 64.8999C74.6 64.5999 75.3 64.3999 75.4 62.8999C75.6 60.5999 74.1 57.6999 72.8 56.9999C71.1 56.0999 66 56.4999 61.5 56.7999C57.5 57.0999 55.4 57.3999 51.2 57.0999C48.8 56.9999 45.7 56.0999 43.3 55.5999C36 54.0999 31.2 54.8999 26.8 56.5999C25.1 57.2999 23.4 57.8999 21.9 58.5999C18.6 59.8999 15.2 60.8999 11.8 61.5999L5.29998 62.8999C3.69998 63.1999 2.59998 64.5999 2.59998 66.1999V84.8999C2.59998 86.9999 4.49998 88.5999 6.59998 88.1999L13.3 86.7999C15.9 86.2999 18.7 86.2999 21.3 86.8999L41.7 91.3999C46.3 92.7999 51.2 92.3999 55.6 90.2999L95.8 71.0999C96.4 70.7999 96.9 70.3999 97.1 69.7999C98.9 64.7999 95.4 61.1999 91.7 61.5999Z" fill={color} />
    <path d="M36.4 44.9002C59.2 43.3002 71.1 33.4002 75.8 28.4002C72.7 33.0002 66.7 40.4002 57 45.3002C56.4 45.6002 56.4 46.5002 57 46.8002C60.8 49.0002 65.4 49.5002 69.8 47.8002C80.4 43.7002 90.9 29.2002 85.9 8.80019C85.6 7.60019 83.8 7.50019 83.4 8.70019C79 20.3002 73.2 17.3002 61.9 19.4002C53.7 20.9002 48.3 28.7002 49.7 36.6002C45.7 37.7002 41.1 38.6002 35.9 38.9002C34.8 39.0002 33.8 39.7002 33.4 40.6002C33.2 41.0002 33.1 41.5002 33.1 42.1002C33.3 43.8002 34.7 45.0002 36.4 44.9002Z" fill="#73A77B" />
  </svg>


);

